<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <app-navbar-vertical-layout />
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import AppNavbarVerticalLayout from "@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue";
import UserDropdown from "@core/layouts/components/app-navbar/components/UserDropdown.vue";
// import locale from "@core/layouts/components/app-navbar/components/Locale.vue";
//import { getUserData } from "@/app/admin/auth/jwt/useJwt";
//import { Admin } from "@/router";
import { mapActions } from "vuex";
export default {
  components: {
    BLink,
    BNavbarNav,

    // Navbar Components
    AppNavbarVerticalLayout,
    UserDropdown,
    // locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    ...mapActions(["app/setDashLang"]),
    isAdmin() {
      return true;
      //getUserData()["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] ==
      //Admin
    },
  },
};
</script>
