import { Admin, SuperAdmin } from "@/router";
export default [
    {
        title: "الرئيسية",
        route: "/admin/home",
        icon: "home-alt",
        roles: [Admin, SuperAdmin],
    },
    {
        header: "الحسابات",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "المستخدمين",
        route: "/admin/users",
        icon: "users-alt",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "الفعاليات",
        route: "/admin/activities",
        icon: "store",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "لوحة التحكم",
        route: "/admin/accounts",
        icon: "user-circle",
        roles: [Admin, SuperAdmin],
    },
    {
        header: "إدارة المحتوى",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "الطلبات",
        route: "/admin/orders",
        icon: "envelope-download",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "الإشعارات",
        route: "/admin/notifications",
        icon: "bell",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "الإعلانات و العروض",
        route: "/admin/adsAndOffers",
        icon: "tv-retro",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "الوظائف",
        route: "/admin/jobs",
        icon: "bag",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "الإشتراكات",
        route: "/admin/subscriptions",
        icon: "receipt",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "التقارير",
        route: "/admin/reports",
        icon: "file-alt",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "الإعدادات",
        route: "/admin/settings",
        icon: "setting",
        roles: [Admin, SuperAdmin],
    },
    {
        title: "تواصل معنا",
        route: "/admin/contactUs",
        icon: "envelope",
        roles: [Admin, SuperAdmin],
    },
];
